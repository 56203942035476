import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface Conversation {
  id?: number;
  name: string;
  meetingDate: Date;
  billDueDate: Date;
  basePay: number;
  unitPay: number;
  reminderFee: number;
  foreclosureFee: number;
}

export interface ConversationState {
  conversation: Conversation | undefined;
  conversations: Conversation[];
  open: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: ConversationState = {
  conversation: undefined,
  conversations: [],
  open: false,
  status: "idle",
};

// export const fetchAssociation = createAsyncThunk(
//   "assocications/fetchAssociation",
//   async (roadId: number) => {
//     const response = await axios(`${baseUrl}/associations?roadId=${roadId}`);
//     const data = await response.data;
//     return data;
//   }
// );

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversation(state, action) {
      state.conversation = action.payload;
    },
    addConversation(state, action) {
      state.conversations.push(action.payload);
    },
    // updateRoad(state, action) {
    //   const roadId = state.roads.findIndex((r) => r.id === action.payload.id);
    //   state.roads[roadId] = action.payload;
    // },
    // deleteRoad(state, action) {
    //   state.roads = state.roads.filter((r: any) => r.id !== action.payload);
    // },
    // setOpen(state) {
    //   state.open = !state.open;
    // },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchAssociation.fulfilled, (state, action) => {
  //     state.association = action.payload;
  //   });
  // },
});

// export function setRoad(road: any) {
//   return async (dispatch: Dispatch) => {
//     dispatch(roadSlice.actions.setRoad(road));
//   };
// }

export function createConversation(recipientId: number, message: string) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(`${baseUrl}/messages`, {
      recipientId,
      content: message,
    });
    dispatch(conversationSlice.actions.addConversation(response.data));
  };
}

// export function updateRoad(id: number, road: any) {
//   return async (dispatch: Dispatch) => {
//     const response = await axios.put(`${baseUrl}/roads/${id}`, road);
//     dispatch(roadSlice.actions.updateRoad(response.data));
//   };
// }

// export function deleteRoad(id: number) {
//   return async (dispatch: Dispatch) => {
//     await axios.delete(`${baseUrl}/roads/${id}`);
//     dispatch(roadSlice.actions.deleteRoad(id));
//   };
// }

// export function openRoadForm() {
//   return async (dispatch: Dispatch) => {
//     dispatch(roadSlice.actions.setOpen());
//   };
// }

export default conversationSlice.reducer;
