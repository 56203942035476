import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField as MuiTextField,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createRoad,
  deleteRoad,
  fetchRoads,
  openRoadForm,
  setRoad,
  updateRoad,
} from "../../redux/slices/road";
import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

mapboxgl.accessToken =
  "pk.eyJ1IjoibXJqb25lc2kiLCJhIjoiY2trNnpheXQ0MDYxYTJvbXMxeXF3M2w5NSJ9.IAcdlc_LT-M-Jce1yDWNbA";

function Mappi(props: any) {
  const mapDiv = useRef<HTMLDivElement>(null);
  let [map, setMap] = useState(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [propertyFeature, setPropertyFeature] = useState<any>(undefined);
  const [zoom, setZoom] = useState(9);
  const [roundedArea, setRoundedArea] = useState<number>(0);
  const [lineLength, setLineLength] = useState<number>(0);

  const { location, geom } = props.road;

  useEffect(() => {
    if (props.road) {
      console.log("props.road", props.road);
      setLng(location.coordinates[0]);
      setLat(location.coordinates[1]);
      setPropertyFeature(geom);
    }
  }, [props.road, location.coordinates, geom]);

  useEffect(() => {
    const attachMap = (
      setMap: React.Dispatch<React.SetStateAction<any>>,
      mapDiv: React.RefObject<HTMLDivElement>
    ) => {
      if (!mapDiv.current) {
        return;
      }
      const map = new mapboxgl.Map({
        // container: mapDiv.current, // ERROR
        container: mapDiv.current || "", // NO ERROR
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [lng, lat],
        zoom: 11,
      });

      map.on("load", () => {
        // setMap(map);
        // map.resize();

        map.addSource("location", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: location,
          },
        });

        map.addLayer({
          id: "location",
          type: "circle",
          source: "location",
          paint: {
            "circle-color": "red",
          },
        });

        map.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: geom,
          },
        });

        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "blue",
            "line-width": 2,
          },
        });

        setMap(map);
      });

      // setMap(map);
    };

    !map && attachMap(setMap, mapDiv);
  }, [map, lat, lng, location, geom]);

  return (
    <Card mb={6}>
      <div>
        <div
          style={{ width: "100%", height: "650px" }}
          ref={mapDiv}
          className="map-container"
        />
      </div>
    </Card>
  );
}

function RoadForm(props: any) {
  const dispatch = useAppDispatch();
  const { road } = useAppSelector((state) => state.road);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (road && road.id !== undefined) {
        dispatch(updateRoad(road.id, values));
      } else {
        dispatch(createRoad(values));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      dispatch(openRoadForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        road
          ? {
              name: road.name,
              municipality: road.municipality,
            }
          : {
              name: "",
              municipality: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <>
            <Typography variant="h6" gutterBottom>
              Tiet
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="name"
                    label="Nimi"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="municipality"
                    label="Kunta"
                    value={values.municipality}
                    error={Boolean(touched.municipality && errors.municipality)}
                    fullWidth
                    helperText={touched.municipality && errors.municipality}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>

            {road !== undefined ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Kartta
                </Typography>
                <Mappi road={road} />
              </>
            ) : null}
          </>
        </Card>
      )}
    </Formik>
  );
}

function RoadList(props: any) {
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tiet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditUser(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Kunta</TableCell>
              <TableCell>Maakunta</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.id} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.municipality}</TableCell>
                <TableCell>{row.county}</TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleShowRoad(row)}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditUser(row)}
                  />{" "}
                  <Delete
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Roads() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { roads, open } = useAppSelector((state) => state.road);

  const handleOpen = () => {
    dispatch(openRoadForm());
  };

  const handleShowRoad = (road: any) => {
    dispatch(setRoad(road));
    dispatch(openRoadForm());
  };

  const handleEditRoad = (road: any) => {
    dispatch(setRoad(road));
    dispatch(openRoadForm());
  };

  const handleDelete = (road: any) => {
    // TODO confirm
    dispatch(deleteRoad(road.id));
  };

  useEffect(() => {
    dispatch(fetchRoads());
  }, [dispatch]);

  console.log("roads", roads);

  return (
    <React.Fragment>
      <Helmet title="Tiet" />
      <Typography variant="h3" gutterBottom display="inline">
        Tiet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Tiet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <RoadForm handleOpen={handleOpen} />
      ) : (
        <RoadList
          rows={roads}
          handleShowRoad={handleShowRoad}
          handleEditUser={handleEditRoad}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Roads;
