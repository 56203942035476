import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignUpComponent from "../../components/auth/SignUp";

import Logo from "../../vendor/tiehoitokunta_logo_transparent.png";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <img
        src={Logo}
        alt="tiehoitokunta_logo"
        style={{ width: "150px", height: "150px" }}
      />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Tiehoitokunta.fi
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Rekisteröidy oheisella lomakkeella
        </Typography>

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
