import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config";
import { Button, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  background-color: #f7f9fc;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Activate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      axios
        .get(`${baseUrl}/auth/activate?token=${token}`)
        .then((response) => {
          navigate("/");
        })
        .catch((error) => {
          console.error("Verification failed:", error);
          setError("Jotain meni vikaan, ota yhteys asiakaspalveluun.");
        });
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Activate" />
        <Typography
          my={10}
          component="h1"
          variant="h1"
          align="center"
          gutterBottom
        >
          Tiehoitokunta.fi
        </Typography>
        <Typography my={10} component="h2" variant="body1" align="center">
          {error ? error : "Aktivoidaan tunnusta..."}
        </Typography>
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button href={"/"} variant="contained" color="primary">
              Etusivu
            </Button>
          </div>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default Activate;
