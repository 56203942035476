import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// import PropertyForm from "./Property";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import * as turf from "@turf/turf";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useAppSelector from "../../hooks/useAppSelector";
import { fetchRoads } from "../../redux/slices/road";
import useAppDispatch from "../../hooks/useAppDispatch";

// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

// import PropertyForm from "./Property";

interface Property {
  id: number;
  name: string;
  code: string;
  address: string;
  area: number;
  information: string;
}

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
  setOpenForm?: void;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const paragraphStyle = {
  fontFamily: "Open Sans",
  margin: 0,
  fontSize: 13,
};

mapboxgl.accessToken =
  "pk.eyJ1IjoibXJqb25lc2kiLCJhIjoiY2trNnpheXQ0MDYxYTJvbXMxeXF3M2w5NSJ9.IAcdlc_LT-M-Jce1yDWNbA";

function Mappi(props: any) {
  const mapDiv = useRef<HTMLDivElement>(null);
  let [map, setMap] = useState(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [roads, setRoads] = useState<any[]>([]);
  const [zoom, setZoom] = useState(9);
  const [roundedArea, setRoundedArea] = useState<number>(0);
  const [lineLength, setLineLength] = useState<number>(0);

  useEffect(() => {
    if (props.roads) {
      setLng(props.roads[0].location.coordinates[0]);
      setLat(props.roads[0].location.coordinates[1]);
      setRoads(props.roads);
    }
  }, [props.roads]);

  useEffect(() => {
    const attachMap = (
      setMap: React.Dispatch<React.SetStateAction<any>>,
      mapDiv: React.RefObject<HTMLDivElement>
    ) => {
      if (!mapDiv.current) {
        return;
      }
      const map = new mapboxgl.Map({
        // container: mapDiv.current, // ERROR
        container: mapDiv.current || "", // NO ERROR
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [lng, lat],
        zoom: 11,
      });

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          line_string: true,
          polygon: true,
          trash: true,
        },
      });

      map.addControl(draw);

      map.on("draw.create", updateDrawing);
      map.on("draw.delete", updateDrawing);
      map.on("draw.update", updateDrawing);

      function updateDrawing(e: any) {
        if (draw.getMode() === "draw_polygon") {
          updateArea(e);
        }
        if (draw.getMode() === "draw_line_string") {
          updateLineLength(e);
        }
      }

      function updateArea(e: any) {
        const data = draw.getAll();
        if (data.features.length > 0) {
          const area = turf.area(data);
          setRoundedArea(Math.round(area * 100) / 100);
        } else {
          setRoundedArea(0);
          if (e.type !== "draw.delete")
            alert("Click the map to draw a polygon.");
        }
      }

      function updateLineLength(e: any) {
        const data = draw.getAll();
        if (data.features.length > 0) {
          const len = turf.length(data, { units: "meters" });
          setLineLength(len);
        } else {
          setLineLength(0);
          if (e.type !== "draw.delete") alert("Click the map to draw a line.");
        }
      }

      map.on("load", () => {
        // setMap(map);
        // map.resize();

        for (let i = 0; i < props.roads.length; i++) {
          map.addSource("route" + i, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: roads[i].geom,
            },
          });

          map.addLayer({
            id: "route" + i,
            type: "line",
            source: "route" + i,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "blue",
              "line-width": 2,
            },
          });
        }

        setMap(map);
      });

      // setMap(map);
    };

    !map && attachMap(setMap, mapDiv);
  }, [map, lat, lng, roads]);

  console.log("props", props);

  return (
    <Card mb={6}>
      <div>
        <div
          style={{ width: "100%", height: "650px" }}
          ref={mapDiv}
          className="map-container"
        />
      </div>
      <div id="calculated-area">
        {roundedArea && (
          <>
            <p style={paragraphStyle}>
              <strong>{roundedArea}</strong> m2
            </p>
          </>
        )}
        {lineLength && (
          <>
            <p style={paragraphStyle}>
              <strong>{lineLength}</strong> meters
            </p>
          </>
        )}
      </div>
    </Card>
  );
}

function Map() {
  const dispatch = useAppDispatch();
  const { roads } = useAppSelector((state) => state.road);

  useEffect(() => {
    dispatch(fetchRoads());
  }, [dispatch]);

  console.log("roads", roads);

  return (
    <React.Fragment>
      <Helmet title="Simple Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Kartta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Kartta</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Mappi roads={roads} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Map;
