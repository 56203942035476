import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function SimpleAccordion() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Ohjeet
        </Typography>
        <Typography variant="body2" gutterBottom>
          Tällä sivulla on koottu ohjeita ohjelman sujuvaan käyttöön.
        </Typography>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Hoitokunta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Hoitokunta ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Osakkaat</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Osakkaat ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Tiet</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Tiet ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kiinteistöt</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Kiinteistöt ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Talous</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Talous ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Yksiköinti</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Yksiköinti ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Viestit</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Viestit ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kokous</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Kokous ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Tiedostot</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Tiedostot ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kartta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Kartta ohjeet</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Yhteys</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Voit ottaa yhteyttä teknisissä ja yleisissä kysymyksissä
                ylläpitoon alla olevan linkin kautta:
              </Typography>
              <Link component={NavLink} to="/contact">
                Yhteys
              </Link>
            </AccordionDetails>
          </Accordion>
        </div>
      </CardContent>
    </Card>
  );
}

function Docs() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Ohjeet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Ohjeet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <SimpleAccordion />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Docs;
