import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import { baseUrl } from "../../../config";
import IncomeStatement from "./IncomeStatement";
import useAuth from "../../../hooks/useAuth";
import { fetchFinancialStatements } from "../../../redux/slices/accounting";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FinancialStatement() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();

  const { incomeStatement, assets, liabilities } = useAppSelector(
    (state) => state.accounting
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function fetchChildren(id: any, data: any) {
    const result: any = [];
    const addedIds = new Set();

    function recursiveSearch(parentId: any) {
      data.forEach((item: any) => {
        if (item.id === parentId && !addedIds.has(item.id)) {
          result.push(item);
          addedIds.add(item.id);
        }
        if (item.parentId === parentId && !addedIds.has(item.id)) {
          result.push(item);
          addedIds.add(item.id);
          recursiveSearch(item.id);
        }
      });
    }

    recursiveSearch(id);
    return result;
  }

  useEffect(() => {
    dispatch(
      fetchFinancialStatements({
        roadId: currentUser?.userRoadsRoad[0].road.id,
        from: "2020-01-01",
        to: "2024-12-31",
      })
    );
  }, [dispatch, currentUser?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Tulos & Tase
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Tulos & Tase</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Tulos & Tase
              </Typography>

              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Tuloslaskelma" {...a11yProps(0)} />
                      <Tab label="Tase vastaavaa" {...a11yProps(1)} />
                      <Tab label="Tase vastattavaa" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <IncomeStatement rows={incomeStatement} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <IncomeStatement rows={assets} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <IncomeStatement rows={liabilities} />
                  </CustomTabPanel>
                </Box>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default FinancialStatement;
