import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const UserRoot = styled.div`
  max-width: 540px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const steps = ["Luo tunnus", "Valitse tiet", "Valitse kiinteistöt"];

interface Step1Props {
  currentStep: number;
}

export const Step1: React.FC<Step1Props> = ({ currentStep }) => {
  if (currentStep !== 1) return null;

  return (
    <>
      <Field name="firstName" my={3}>
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Etunimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.firstName && form.touched.firstName}
            helperText={<ErrorMessage name="firstName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Sukunimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.lastName && form.touched.lastName}
            helperText={<ErrorMessage name="lastName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="email">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Sähköposti"
            variant="outlined"
            fullWidth
            error={!!form.errors.email && form.touched.email}
            helperText={<ErrorMessage name="email" />}
            my={2}
          />
        )}
      </Field>
      <Field name="password">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Salasana"
            variant="outlined"
            type="password"
            fullWidth
            error={!!form.errors.password && form.touched.password}
            helperText={<ErrorMessage name="password" />}
            my={2}
          />
        )}
      </Field>
    </>
  );
};

interface Step2Props {
  currentStep: number;
}

export const Step2: React.FC<Step2Props> = ({ currentStep }) => {
  if (currentStep !== 2) return null;

  return (
    <>
      <Field name="streetName" my={3}>
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Tien nimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.streetName && form.touched.streetName}
            helperText={<ErrorMessage name="streetName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="municipality">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Kunta"
            variant="outlined"
            fullWidth
            error={!!form.errors.municipality && form.touched.municipality}
            helperText={<ErrorMessage name="municipality" />}
            my={2}
          />
        )}
      </Field>
    </>
  );
};

function SignUp() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  const [success, setSuccess] = useState<boolean>(false);

  const validationSchema = [
    Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    Yup.object({
      streetName: Yup.string().required("street name is required"),
      municipality: Yup.string().required("municipality is required"),
    }),
  ];

  // Initial values for the form
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    streetName: "",
    municipality: "",
  };

  const [currentStep, setCurrentStep] = useState(1);

  const isLastStep = currentStep === validationSchema.length;

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      // Handle form submission
      console.log(values);

      try {
        signUp({
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          streetName: values.streetName,
          municipality: values.municipality,
        });
        setSuccess(true);
        // navigate("/login");
      } catch (error: any) {
        const message = error.message || "Something went wrong";

        //setStatus({ success: false });
        //setErrors({ submit: message });
        //setSubmitting(false);
      }
    } else {
      // Move to the next step
      setCurrentStep((prevStep) => prevStep + 1);
    }
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  if (success) {
    return (
      <>
        <p>Aktivoi tunnuksesi sähköpostista löytyvästä linkistä.</p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
            sx={{ mr: 1 }}
          >
            Kirjaudu
          </Button>
        </Box>
      </>
    );
  } else {
    return (
      <UserRoot>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep - 1]}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Step1 currentStep={currentStep} />
              <Step2 currentStep={currentStep} />

              <div>
                <Button
                  type={"submit"}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLastStep ? "Lähetä" : "Seuraava"}
                </Button>
                {currentStep > 1 && (
                  <Button
                    style={{ marginRight: "8px" }}
                    type={"button"}
                    color="primary"
                    onClick={prevStep}
                    fullWidth
                  >
                    Takaisin
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </UserRoot>
    );
  }
}

export default SignUp;
