import { createContext, ReactNode, useEffect, useReducer } from "react";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";

import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";
import { baseUrl } from "../config";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.tsx` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    currentUser: AuthUser;
  };
  [SIGN_IN]: {
    currentUser: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    currentUser: AuthUser;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  currentUser: null,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        currentUser: action.payload.currentUser,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          console.log("accessToken", accessToken);

          const response = await axios.get(`${baseUrl}/auth/verify`);
          const { user } = response.data;

          console.log("res", response.data);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              currentUser: user,
            },
          });
        } else {
          console.log("not logged in");
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              currentUser: null,
            },
          });
        }
      } catch (err) {
        console.error("err", err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            currentUser: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    const response = await axios.post(`${baseUrl}/auth/login`, {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        currentUser: user,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (data: any) => {
    const response = await axios.post(`${baseUrl}/auth/register`, data);
    const { accessToken, user } = response.data;

    // window.localStorage.setItem("accessToken", accessToken);
    // dispatch({
    //   type: SIGN_UP,
    //   payload: {
    //     currentUser: user,
    //   },
    // });
  };

  const resetPassword = (email: string) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
