import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import userReducer from "./slices/user";
import roadReducer from "./slices/road";
import propertyReducer from "./slices/property";
import accountingReducer from "./slices/accounting";
import associationReducer from "./slices/association";
import conversationReducer from "./slices/conversation";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    road: roadReducer,
    property: propertyReducer,
    accounting: accountingReducer,
    association: associationReducer,
    conversation: conversationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
