import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { createConversation } from "../../redux/slices/conversation";
import useAppDispatch from "../../hooks/useAppDispatch";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Contact() {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    dispatch(createConversation(1, message));
    setMessage("");
  };
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Yhteys
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Yhteys</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                Yhteys
              </Typography>
              <Typography variant="body2" gutterBottom>
                Voit olla yhteydessä ylläpitoon lähettämällä viestin tässä.
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  required
                  id="outlined-multiline-flexible"
                  label="Viesti"
                  multiline
                  rows={10}
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                />
                <div style={{ marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={handleSubmit}
                  >
                    Lähetä
                  </Button>
                  <Button variant="outlined" onClick={() => setMessage("")}>
                    Peruuta
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contact;
