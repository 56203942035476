import React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowDown, ArrowUp } from "react-feather";

function IncomeStatement(props: any) {
  const [open, setOpen] = React.useState(true);

  return (
    <Table style={{ marginTop: "20px" }} size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Nimi</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(props.rows || []).map((row: any) => (
          <>
            <TableRow key={row.id} hover>
              <TableCell>
                {row.accounts ? (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <ArrowUp /> : <ArrowDown />}
                  </IconButton>
                ) : null}
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(row.value)}
              </TableCell>
            </TableRow>
            {row.accounts
              ? row.accounts.map((account: any) => (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{account.code}</TableCell>
                    <TableCell>{account.name}</TableCell>
                    <TableCell>
                      {account.value
                        ? new Intl.NumberFormat("fi-FI", {
                            style: "currency",
                            currency: "EUR",
                          }).format(row.value)
                        : null}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </>
        ))}
      </TableBody>
    </Table>
  );
}

export default IncomeStatement;
