import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  CardContent as MuiCardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import { baseUrl } from "../../../config";
import {
  createVoucher,
  fetchLedger,
  fetchVouchers,
  updateVoucher,
} from "../../../redux/slices/accounting";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAuth from "../../../hooks/useAuth";
import { Delete, Edit, Search } from "@mui/icons-material";
import { format } from "date-fns";
import { Formik } from "formik";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DatePicker = styled(MuiDatePicker)<{ my?: number }>(spacing);

function VoucherForm(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { voucher } = useAppSelector((state) => state.accounting);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (voucher && voucher.id !== undefined) {
        dispatch(updateVoucher(voucher.id, values));
      } else {
        dispatch(createVoucher(values, currentUser?.userRoadsRoad[0].road.id));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      //dispatch(openRoadForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        description: "",
        date: null,
      }}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Tosite
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="description"
                    label="Nimi"
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    onChange={(value) => setFieldValue("date", value)}
                    value={values.date}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.date && errors.date)}
                        helperText={touched.date && errors.date}
                        label="Pvm"
                        margin="normal"
                        name="date"
                        variant="standard"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                  {/* <DatePicker
                    label="Pvm"
                    value={values.date}
                    onChange={(val) => {
                      setFieldValue(field.date, val);
                    }}
                    //onChange={handleChange}
                    // onChange={(newValue) => {
                    //   setValue(newValue);
                    // }}
                    renderInput={(params) => <TextField {...params} />}
                    my={2}
                  /> */}
                  {/* <TextField
                    name="date"
                    label="Pvm"
                    value={values.date}
                    error={Boolean(touched.date && errors.date)}
                    fullWidth
                    helperText={touched.date && errors.date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> */}
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function VoucherList(props: any) {
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tosittet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditVoucher(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Kuvaus</TableCell>
              <TableCell>Pvm</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.rows || []).map((row: any) => (
              <TableRow key={row.id} hover>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {format(new Date(row.date), "dd-MM-yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => console.log("show")}
                  />{" "}
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleEditUser(row)}
                  />{" "}
                  <Delete
                    style={{ cursor: "pointer" }}
                    onClick={() => props.handleDelete(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Voucher() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { vouchers } = useAppSelector((state) => state.accounting);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
    //dispatch(openRoadForm());
  };

  const handleEditVoucher = (road: any) => {
    setOpen(!open);
    //dispatch(setRoad(road));
    //dispatch(openRoadForm());
  };

  const handleDelete = (voucher: any) => {
    // TODO confirm
    //dispatch(deleteRoad(road.id));
  };

  useEffect(() => {
    dispatch(
      fetchVouchers({
        roadId: currentUser?.userRoadsRoad[0].road.id,
      })
    );
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Tositteet" />
      <Typography variant="h3" gutterBottom display="inline">
        Tositteet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Tositteet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <VoucherForm handleOpen={handleOpen} />
      ) : (
        <VoucherList
          rows={vouchers}
          handleEditVoucher={handleEditVoucher}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Voucher;
